import { ProductVariant } from "@models/models";
import _ from "lodash";
import dayjs from "dayjs";
import { MoneyAmount } from "@medusajs/medusa";
dayjs.extend(require("dayjs/plugin/isBetween"));
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));
dayjs.extend(require("dayjs/plugin/utc"));
type DisplayPrice = {
  label?: string;
  higher_price?: MoneyAmount;
  price: MoneyAmount;
};
export default function getDisplayPrice(
  variant: ProductVariant,
  currency_code: string,
): DisplayPrice {
  // @ts-ignore
  const now = dayjs.utc();
  const filtered_prices = variant.prices.filter((p) => {
    if (p.currency_code !== currency_code) {
      return false;
    }
    if (p.price_list?.starts_at && p.price_list?.ends_at) {
      return now.isBetween(
        // @ts-ignore
        dayjs.utc(new Date(p.price_list?.starts_at)),
        // @ts-ignore
        dayjs.utc(new Date(p.price_list?.ends_at)),
      );
    } else if (p.price_list?.starts_at) {
      return now.isSameOrAfter(
        // @ts-ignore
        dayjs.utc(new Date(p.price_list?.starts_at)),
      );
    } else if (p.price_list?.ends_at) {
      return now.isSameOrBefore(
        // @ts-ignore
        dayjs.utc(new Date(p.price_list?.ends_at)),
      );
    }
    return true;
  });
  const sorted_prices = _.sortBy(filtered_prices, (p) => p.amount);
  return {
    price: sorted_prices[0],
    higher_price:
      sorted_prices.length > 1 &&
      sorted_prices[0].amount < sorted_prices[sorted_prices.length - 1].amount
        ? sorted_prices[sorted_prices.length - 1]
        : undefined,
  };
}
