import React, { useEffect, useRef, useState } from "react";

type ProductQuantitySelectorProps = {
  setSelectedQuantity: (quantity: number) => void;
};
export default function ProductQuantitySelector({
  setSelectedQuantity,
}: ProductQuantitySelectorProps) {
  const [showManualQuantity, setShowManualQuantity] = useState(false);
  const quantityRef = useRef<HTMLInputElement>(null);
  const [quantity, setQuantity] = useState(2);

  useEffect(() => {
    if (quantityRef.current) quantityRef.current.focus();
  }, [quantityRef, showManualQuantity]);

  const updateQuantity = (quantity: string) => {
    if (quantity.trim() === "") return;
    if (quantity === "10+") {
      setShowManualQuantity(true);
      return;
    }
    const numQuantity = parseInt(quantity);
    if (isNaN(numQuantity)) return;
    setSelectedQuantity(numQuantity);
  };

  return (
    <div className={"flex justify-center w-full"}>
      <div className={"flex flex-col w-full items-center"}>
        <label
          htmlFor="quantity"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Quantity
        </label>
        <div>
          {!showManualQuantity ? (
            <select
              id="quantity"
              name="location"
              onChange={(e) => updateQuantity(e.target.value)}
              className="select select-bordered w-64 max-w-xs"
              defaultValue={quantity}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>10+</option>
            </select>
          ) : (
            <input
              ref={quantityRef}
              autoComplete={"off"}
              name={"quantity"}
              type={"text"}
              onChange={(e) => updateQuantity(e.target.value)}
              className="input input-bordered"
            />
          )}
        </div>
      </div>
    </div>
  );
}
