import {
  MarketingStrategy,
  ReminderNotificationStrategy,
  ReviewEmailStrategy,
  SendSaleNotificationsStrategy,
} from "../models/models";

export const PAYMENT_STRATEGIES = [
  {
    id: "instant-payments",
    name: "Every order is immediately paid out",
  },
  {
    id: "skip-destination-account",
    name: "One-time payment at the end using Stripe",
  },
  {
    id: "interac-email-money-transfer",
    name: "One-time payment at the end using Interac Email Money Transfer",
  },
  {
    id: "hold",
    name: "Make manual arrangements to pay",
  },
];

export const SEND_SALE_NOTIFICATIONS_STRATEGIES = [
  {
    id: SendSaleNotificationsStrategy.SEND,
    name: "Send",
  },
  { id: SendSaleNotificationsStrategy.DO_NOT_SEND, name: "Do not send" },
];

export const REVIEW_EMAIL_STRATEGIES = [
  {
    id: ReviewEmailStrategy.SEND_DEFAULT,
    name: "Send Default Email (this ignores the email design below)",
  },
  {
    id: ReviewEmailStrategy.SEND_CUSTOM,
    name: "Send Custom Email That You Design Below",
  },
  { id: ReviewEmailStrategy.DO_NOT_SEND, name: "Do Not Send an Email " },
];

export const MARKETING_STRATEGIES = [
  {
    id: MarketingStrategy.OPT_IN,
    name: "Customer has to opt-in during checkout",
  },
  {
    id: MarketingStrategy.ADD_AUTOMATICALLY,
    name: "Automatically add to mailing list",
  },
];

export enum ReportingTimeIntervalValues {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
}

export const REPORTING_TIME_INTERVALS = [
  { id: ReportingTimeIntervalValues.DAILY, name: "Daily" },
  { id: ReportingTimeIntervalValues.WEEKLY, name: "Weekly" },
  { id: ReportingTimeIntervalValues.MONTHLY, name: "Monthly" },
  { id: ReportingTimeIntervalValues.QUARTERLY, name: "Quarterly" },
];

export const CONTEST_ENTRY_RULES = [
  { id: "once-per-email", name: "Once per lifetime" },
  { id: "once-per-24-hours", name: "Once per 24 hours" },
];

export enum DateRangeOptions {
  CUSTOM = "custom",
  TODAY = "today",
  YESTERDAY = "yesterday",
  LAST_WEEK = "last-week",
  LAST_MONTH = "last-month",
  LAST_7_DAYS = "last-7-days",
  LAST_30_DAYS = "last-30-days",
}
export const DATE_RANGE_OPTIONS = [
  { id: DateRangeOptions.CUSTOM, name: "Custom" },
  { id: DateRangeOptions.TODAY, name: "Today" },
  { id: DateRangeOptions.YESTERDAY, name: "Yesterday" },
  { id: DateRangeOptions.LAST_WEEK, name: "Last Week" },
  { id: DateRangeOptions.LAST_MONTH, name: "Last Month" },
  { id: DateRangeOptions.LAST_7_DAYS, name: "Last 7 Days" },
  { id: DateRangeOptions.LAST_30_DAYS, name: "Last 30 Days" },
];

export const REMINDER_OPTIONS = [
  { id: ReminderNotificationStrategy.DO_NOT_SEND, name: "Do not send" },
  { id: ReminderNotificationStrategy.SEND_DEFAULT, name: "Send" },
];

export const TRUE_OR_FALSE = [
  { id: "false", name: "No" },
  { id: "true", name: "Yes" },
];

export const PRIZE_PERIODS = [
  { id: "86400", name: "Per 24 Hours" },
  { id: "172800", name: "Per 48 Hours" },
];

export enum AnalyticsElementType {
  META_PIXEL = "meta-pixel",
  GOOGLE_TAG = "google-tag",
  TRADABLE_BITS_PIXEL = "tradable-bits-pixel",
}

export function getNameFromId(
  id: string,
  nameList: { id: string; name: any }[],
): string | undefined {
  const result = nameList.find((item) => item.id === id);
  return result ? result.name : undefined;
}
