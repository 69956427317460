import { ContentBlock } from "../models/models";
import Image from "next/image";
import * as React from "react";

type ProductCardsProps = {
  cards: ContentBlock[];
};
export default function ProductContentBlocks({ cards }: ProductCardsProps) {
  return (
    <div className={"flex flex-col gap-y-4"}>
      {cards.map((c) => (
        <div
          key={c.id}
          className="relative card card-compact bg-base-100 shadow-xl"
        >
          {c.thumbnail && (
            <figure>
              <Image
                alt={c.title}
                width={500}
                height={300}
                src={c.thumbnail as string}
              />
            </figure>
          )}
          <div className="card-body items-center text-center">
            {c.title && <div className={"card-title"}>{c.title}</div>}
            {<p className={"text-gray-500"}>{c.text}</p>}
          </div>
        </div>
      ))}
    </div>
  );
}
