"use client";
import { useLazyGetProductDetailQuery } from "@store/apiSlice";
import { useSelector } from "react-redux";
import { selectCartState } from "@store/cart-slice";
import { useEffect, useState } from "react";
import { Product } from "@models/models";
import EventProductDetail from "@components/event-product-detail";
import { productTransformer } from "../transformers/getProduct";
import structuredClone from "@ungap/structured-clone";

export default function EventProductDetailWrapper({
  productId,
}: {
  productId: string;
}) {
  const [getProductDetail] = useLazyGetProductDetailQuery();
  const cart = useSelector(selectCartState);
  const [product, setProduct] = useState<Product>();
  const loadProduct = async (
    productId: string,
    cartId: string,
    regionId: string,
  ) => {
    let product = await getProductDetail({
      productId: productId,
      regionId: regionId,
      cart_id: cartId,
    }).unwrap();
    setProduct(productTransformer(structuredClone(product)));
  };

  useEffect(() => {
    if (!productId || !cart?.region_id || !cart?.id) return;
    loadProduct(productId, cart.id, cart.region_id);
  }, [productId, cart?.region_id, cart?.id, getProductDetail]);
  return <>{product && <EventProductDetail product={product} />}</>;
}
