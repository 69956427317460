import Script from "next/script";

export default function MetaPixelPageViewTracker({
  pixelId,
}: {
  pixelId: string;
}) {
  const pixelCode = `
      fbq('init', '${pixelId}'); 
      fbq('track', 'PageView'); 
  `;

  return (
    <>
      <Script
        id={`meta-pixel-${pixelId}`}
        dangerouslySetInnerHTML={{
          __html: pixelCode,
        }}
      />
    </>
  );
}
