import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { Product } from "@models/models";

export default function useProductPageViewed(product: Product) {
  const posthog = usePostHog();

  useEffect(() => {
    if (!posthog) return;
    posthog.capture("product_page_viewed", {
      custom: {
        productId: product.id,
        accountId: product.account.id,
        productHandle: product.handle,
      },
    });
  }, [posthog]);
}
