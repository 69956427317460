import { ApiError } from "../models/models";

export function isApiError(obj: any): obj is ApiError {
  const o = obj as ApiError;
  return (
    o !== undefined &&
    o.error !== undefined &&
    o.error !== null &&
    o.error.length !== 0
  );
}
