import { addLineItemToCart, replaceCart } from "@store/cart-slice";
import { isApiError } from "@guards/isApiError";
import { toastWarning } from "@utils/display-errors";
import { createAddToCartPayload, sendGaEvent } from "@utils/google-analytics";
import { Cart } from "@medusajs/medusa";
import toast from "react-hot-toast";
import { Product } from "@models/models";
import { useCart } from "@hooks/cart";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { useAppDispatch } from "@store/store";

export default function useAddToCart(product: Product) {
  const cart = useCart();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [addToCartMessageRead, setAddToCartMessageRead] = useState(false);
  const [showAddToCartMessage, setShowAddToCartMessage] = useState(false);
  const [action, setAction] = useState("");
  const [loadingAddToCart, setLoadingAddToCart] = useState(false);
  const [payWhatYouCanPrice, setPayWhatYouCanPrice] = useState<number>();

  const addToCart = async (
    action: string,
    variant_id: string,
    quantity: number,
    navigate_to_cart_after_adding: boolean,
    options?: { skip_add_to_cart_message_check?: boolean },
  ) => {
    if (
      product.add_to_cart_message &&
      product.add_to_cart_message.trim() !== "" &&
      !addToCartMessageRead &&
      !options?.skip_add_to_cart_message_check
    ) {
      setShowAddToCartMessage(true);
      setAction(action);
      return;
    }
    setLoadingAddToCart(true);
    const lineItem = {
      variant_id: variant_id,
      quantity: quantity,
      pay_what_you_can_price: payWhatYouCanPrice,
    };
    const result = await dispatch(
      addLineItemToCart({ ...lineItem, cartId: cart?.id as string }),
    );
    dispatch(replaceCart(result.payload));

    if (isApiError(result.payload)) {
      toastWarning(result.payload);
    } else {
      sendGaEvent(
        "add_to_cart",
        createAddToCartPayload(result.payload as Cart, variant_id),
      );
      if (!navigate_to_cart_after_adding) {
        setLoadingAddToCart(false);
        return;
      }
      if (action === "checkout") {
        toast.success("Tickets added to cart!");
        router.push("/store/cart");
      } else {
        toast.success("Tickets added to cart!");
      }
    }
    setLoadingAddToCart(false);
  };

  return {
    addToCart,
    setPayWhatYouCanPrice,
    loadingAddToCart,
    showAddToCartMessage,
    setAddToCartMessageRead,
    setShowAddToCartMessage,
    action,
  };
}
