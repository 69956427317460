"use client";
import { useSelector } from "react-redux";
import { selectAdmin, setAdmin } from "../store/user-slice";
import { useEffect, useState } from "react";
import { useLazyGetAdminUserQuery } from "../store/apiSlice";
import { useAppDispatch } from "../store/store";
import { useRouter } from "next/navigation";
import { User } from "@medusajs/medusa";
import { AccountPrivilegesBare, AuthError } from "../models/models";
import { selectAccount } from "../store/account-slice";
import _ from "lodash";
import { usePostHog } from "posthog-js/react";

export const ROLE_ADMIN = "admin";

export function isAdminUser(obj: User | AuthError | undefined): obj is User {
  return obj !== undefined && (obj as User).api_token !== undefined;
}

type UseAdminUserProps = {
  redirectIfLoggedOut: boolean;
};

export enum Privileges {
  FULL = "full",
  SCANNER = "scanner",
  MANAGER = "manager",
  COMIC_PROFILE = "comic-profile",
}

export function useAdminUser(
  { redirectIfLoggedOut }: UseAdminUserProps = { redirectIfLoggedOut: true },
) {
  const adminUser = useSelector(selectAdmin);
  const [picture, setPicture] = useState<string | undefined>();
  const [roles, setRoles] = useState<string[]>([]);
  const [accountPrivileges, setAccountPrivileges] = useState<
    AccountPrivilegesBare[]
  >([]);
  const [getAdminUser] = useLazyGetAdminUserQuery();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const account = useSelector(selectAccount);
  const posthog = usePostHog();

  useEffect(() => {
    const load = async () => {
      const { data, isError } = await getAdminUser();
      if (!isError && isAdminUser(data)) {
        dispatch(setAdmin(data));
        setLoggedInFields(data);
        posthog.identify(
          data.id, // Replace 'distinct_id' with your user's unique identifier
          { email: data.email, name: data.first_name + " " + data.last_name }, // optional: set additional user properties
        );
      } else if (redirectIfLoggedOut) {
        router.push("/admin/not-logged-in");
      }
    };
    if (!adminUser) {
      load();
    } else if (isAdminUser(adminUser)) {
      posthog.identify(
        adminUser.id, // Replace 'distinct_id' with your user's unique identifier
        {
          email: adminUser.email,
          name: adminUser.first_name + " " + adminUser.last_name,
        }, // optional: set additional user properties
      );
      setLoggedInFields(adminUser);
    } else {
      console.log("Not logged in and not admin");
    }
  }, [adminUser]);

  const setLoggedInFields = (adminUser: User) => {
    let picture =
      "https://images.unsplash.com/photo-1586374579358-9d19d632b6df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80";
    const profile = adminUser.metadata?.profile as any;
    if (profile && profile.picture) {
      picture = profile.picture;
    }
    const rolesNamespace = "https://mycomedytickets.com/roles";
    if (profile && profile[rolesNamespace]) {
      setRoles(profile[rolesNamespace]);
    }

    const access = adminUser.metadata?.access as any;
    if (access?.accountPrivileges) {
      setAccountPrivileges(access.accountPrivileges);
    }

    setPicture(picture);
  };

  const hasRole = (role: string | string[] | undefined) => {
    if (!role) return false;
    if (Array.isArray(role)) {
      return role.some((r) => roles.includes(r));
    } else {
      return roles.includes(role);
    }
  };

  const hasFullPrivilege = () => {
    const privileges = accountPrivileges
      .filter((ap) => ap.accountId === account?.id)
      .flatMap((ap) => ap.privileges)
      .flat();
    return privileges.some((p) => p === "full");
  };

  const hasPrivilege = (requiredPrivileges?: string[]) => {
    if (!requiredPrivileges) return false;
    const privileges = accountPrivileges
      .filter((ap) => ap.accountId === account?.id)
      .flatMap((ap) => ap.privileges)
      .flat();
    const hasFull = privileges.some((p) => p === "full");
    return (
      hasFull || _.intersection(privileges, requiredPrivileges).length !== 0
    );
  };

  return {
    loggedIn: adminUser !== undefined,
    apiToken: adminUser?.api_token,
    email: adminUser?.email,
    id: adminUser?.id,
    participant: adminUser?.participant,
    picture,
    hasRole,
    hasPrivilege,
    hasFullPrivilege,
  };
}
