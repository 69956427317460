import { Venue } from "../models/models";
import { VenueAddress } from "./venue-address";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import { FaCalendar, FaCalendarAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

type VenueProps = {
  venue: Venue;
};
export function Venue({ venue }: VenueProps) {
  const card_background_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.card_background_color),
  );

  return (
    <div
      className={`grid-cols-1 p-4`}
      style={{ backgroundColor: card_background_color }}
    >
      <div className="grid grid-cols-2 gap-8">
        <div className={"flex flex-col p-2 gap-y-2"}>
          <h2 className="card-title">{venue.name}</h2>
          {venue.is_virtual ? (
            <div>{venue.description}</div>
          ) : (
            <div>
              <VenueAddress venue={venue} />
            </div>
          )}
          <div className={"card-actions"}>
            {!venue.is_virtual && (
              <Link
                target={"_blank"}
                href={`https://maps.google.com/?q=${venue.name} ${venue.address} ${venue.city.name} ${venue.province} ${venue.postalCode}`}
                className="btn btn-outline btn-xs"
              >
                Google Maps
              </Link>
            )}
            <Link
              className={"btn btn-outline btn-xs"}
              href={`/x/${venue.slug}`}
            >
              See more events at this venue
            </Link>
          </div>
        </div>
        <div className={"p-2"}>
          <Image
            height={300}
            width={300}
            src={venue.thumbnail}
            alt={venue.name}
            className={"object-fill"}
          />
        </div>
      </div>
    </div>
  );
}
