import React from "react";
import { Venue } from "../models/models";

export function CityName({ venue }: { venue: Venue }) {
  return (
    <div className="font-sans p-1 bg-accent uppercase text-white text-sm text-center bg-jump">
      {venue.is_virtual ? (
        <span>Virtual Event</span>
      ) : (
        <span>
          {venue.city.name}, {venue.province}
        </span>
      )}
    </div>
  );
}
