import { useEffect, useState } from "react";
import { LocationResult } from "../models/models";

const torontoLocation: LocationResult = {
  status: "OK",
  city: "Toronto",
  lat: 43.65107,
  lon: -79.347015,
  country: "Canada",
  countryCode: "CA",
  region: "ON",
  regionName: "Ontario",
  timezone: "America/Toronto",
};

export default function useLocationInfo() {
  const [locationInfo, setLocationInfo] = useState<
    LocationResult | undefined
  >();

  useEffect(() => {
    const result = localStorage.getItem("location");
    if (result) {
      setLocationInfo(JSON.parse(result));
    } else {
      setLocationInfo(torontoLocation);
    }
  }, []);

  return locationInfo;
}
