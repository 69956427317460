import React from "react";
import { Product } from "@models/models";

export default function ProductDetailTitleDesktop({
  product,
}: {
  product: Product;
}) {
  return (
    <h1 className="hidden lg:block text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
      {product.title}
    </h1>
  );
}
