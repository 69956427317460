import Image from "next/image";
import React from "react";
import { Product } from "../models/models";

export default function ProductThumbnail({ product }: { product: Product }) {
  return (
    <>
      {product.thumbnail && (
        <Image
          alt={product.title}
          src={product.thumbnail as string}
          priority={true}
          width={500}
          height={500}
          className="w-full h-full object-center object-cover"
        />
      )}
    </>
  );
}
