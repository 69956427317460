import { Message } from "@components/message";
import React from "react";

export default function NoPricesForCountryMessage() {
  return (
    <Message
      message={
        "There are no prices for this country. Try changing the country in the top-left corner by clicking on the flag."
      }
      title={"No Prices for Country"}
      type={"warning"}
    />
  );
}
