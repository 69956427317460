import { VariantParticipant } from "../models/models";
import Image from "next/image";
import React from "react";
import Link from "next/link";
import { ParticipantSocial } from "./participant-social";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

type ParticipantsProps = {
  variantParticipants: VariantParticipant[] | undefined;
};
export function Participants({ variantParticipants }: ParticipantsProps) {
  const card_background_color = useSelector(
    selectBrandThemeSetting(BRAND_THEME_SETTING_KEYS.card_background_color),
  );

  return (
    <>
      {variantParticipants &&
        variantParticipants.map(({ role, participant: p }) => (
          <div key={p.id} className="p-2 w-full">
            <div
              className="h-full flex items-center border-gray-200 border p-4 rounded-lg"
              style={{ backgroundColor: card_background_color }}
            >
              <Link href={`/p/${p.slug}`}>
                <Image
                  alt="team"
                  height={200}
                  width={200}
                  className="w-24 h-24 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                  src={p.picture}
                />
              </Link>
              <div className="flex-grow">
                {p.slug && (
                  <Link href={`/p/${p.slug}`}>
                    <h2 className="text-gray-900 title-font font-medium">
                      {p.name}
                    </h2>
                  </Link>
                )}
                {!p.slug && (
                  <h2 className="text-gray-900 title-font font-medium">
                    {p.name}
                  </h2>
                )}
                {role === "headliner" && (
                  <span className={"badge badge-secondary"}>Headliner</span>
                )}
                <ParticipantSocial participant={p} />
              </div>
            </div>
          </div>
        ))}
    </>
  );
}
