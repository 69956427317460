import React from "react";
import { useField } from "formik";

export const TextInput = ({
  label,
  secondaryLabel,
  textarea,
  required,
  ...props
}: any) => {
  const [field] = useField(props);
  return (
    <>
      {!textarea && (
        <label className="form-control">
          {label && (
            <div className="label">
              <span className="label-text">
                {label}
                {required ? (
                  <span>
                    <sup className={"text-red-600 font-bold"}>*</sup>
                  </span>
                ) : (
                  ""
                )}
              </span>
            </div>
          )}
          <div>
            <input
              className={`${props.additionalClassNames ? props.additionalClassNames : ""} w-full input input-bordered`}
              {...field}
              {...props}
            />
            {secondaryLabel}
          </div>
        </label>
      )}
      {textarea && (
        <label className="form-control">
          <div className="label">
            <span className="label-text">{label}</span>
          </div>
          <textarea
            rows="6"
            {...props}
            {...field}
            className={`${props.additionalClassNames ? props.additionalClassNames : ""} textarea textarea-bordered`}
          ></textarea>
        </label>
      )}
    </>
  );
};
