import Image from "next/image";
import React from "react";
import { Product } from "@models/models";

export default function ProductDetailImageAndTitleMobile({
  product,
}: {
  product: Product;
}) {
  return (
    <div
      className={
        "w-full justify-between flex flex-row gap-4 lg:hidden items-center"
      }
    >
      <div className={"font-extrabold tracking-tight  text-2xl md:text-3xl"}>
        {product.title}
      </div>
      <div className={"flex-none"}>
        {product.thumbnail && (
          <Image
            alt={product.title}
            src={product.thumbnail as string}
            priority={true}
            width={50}
            height={50}
            className="rounded-xl w-full h-full object-center object-cover"
          />
        )}
      </div>
    </div>
  );
}
