import { AnalyticsElement } from "@models/models";
import React from "react";
import MetaPixelPageViewTracker from "@components/vendors/meta-pixel-page-view-tracker";
import { AnalyticsElementType } from "@utils/reference";

export default function PageViewTracker({
  analyticsElements,
}: {
  analyticsElements: AnalyticsElement[];
}) {
  return (
    <div>
      {analyticsElements.map((e) => (
        <React.Fragment key={e.id}>
          {e.analytics_element_type.identifier ===
            AnalyticsElementType.META_PIXEL && (
            <MetaPixelPageViewTracker pixelId={e.key} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
