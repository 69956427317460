import { useEffect, useState } from "react";
import { assignRegion, replaceCart } from "@store/cart-slice";
import { useCart } from "@hooks/cart";
import { Product } from "@models/models";
import { useMedusa } from "@hooks/medusa";
import { useLazyGetRegionsQuery } from "@store/apiSlice";
import { useAppDispatch } from "@store/store";

export default function useCurrencyBasedCartRegionSwitcher(product: Product) {
  const cart = useCart();
  const { medusa } = useMedusa();
  const [getRegions] = useLazyGetRegionsQuery();
  const dispatch = useAppDispatch();
  const [hasPricesForCartRegion, setHasPricesForCartRegion] =
    useState<boolean>();
  useEffect(() => {
    if (!cart?.region?.currency_code) return;
    const cartCurrency = cart.region.currency_code;
    const productCurrencies = product.variants.flatMap((v) =>
      v.prices.map((p) => p.currency_code),
    );
    const has_cart_currency = productCurrencies.includes(cartCurrency);
    if (!has_cart_currency) {
      getRegions().then(async (r) => {
        const regions = r.data;
        if (regions) {
          let found_price = false;
          for (const r of regions) {
            if (productCurrencies.includes(r.currency_code)) {
              dispatch(assignRegion(r));
              const cart_response = await medusa.carts.update(
                cart.id as string,
                {
                  region_id: r.id,
                },
              );
              dispatch(replaceCart(cart_response.cart));
              found_price = true;
              break;
            }
          }
          setHasPricesForCartRegion(found_price);
        } else {
          setHasPricesForCartRegion(false);
        }
      });
    } else {
      setHasPricesForCartRegion(true);
    }
  }, [cart, product]);

  return { hasPricesForCartRegion };
}
