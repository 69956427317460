import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";
import React from "react";
import PageViewTracker from "@components/vendors/page-view-tracker";
import { Product } from "@models/models";

export default function ProductDetailContainer({
  children,
  product,
}: {
  product: Product;
  children: React.ReactNode;
}) {
  const product_detail_background_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.product_detail_background_color,
    ),
  );
  if (!product_detail_background_color) return <div>Loading...</div>;

  return (
    <div
      style={{ backgroundColor: product_detail_background_color }}
      className={`p-2 rounded`}
    >
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 flex flex-col lg:flex-row lg:gap-x-8">
        {children}
      </div>
      <PageViewTracker analyticsElements={product.account.analytics_elements} />
    </div>
  );
}
