import { FaStar } from "react-icons/fa";

type RatingStarProps = {
  rating: number;
  onClick?: (rating: number) => void;
};
export default function RatingStars({ rating, onClick }: RatingStarProps) {
  const colorClassName = (current: number) => {
    return rating && current <= rating ? "text-yellow-500" : "";
  };
  return (
    <div className={"flex"}>
      <FaStar
        className={`text-3xl ${colorClassName(1)}`}
        onClick={() => (onClick ? onClick(1) : () => "")}
      />
      <FaStar
        className={`text-3xl ${colorClassName(2)}`}
        onClick={() => (onClick ? onClick(2) : () => "")}
      />
      <FaStar
        className={`text-3xl ${colorClassName(3)}`}
        onClick={() => (onClick ? onClick(3) : () => "")}
      />
      <FaStar
        className={`text-3xl ${colorClassName(4)}`}
        onClick={() => (onClick ? onClick(4) : () => "")}
      />
      <FaStar
        className={`text-3xl ${colorClassName(5)}`}
        onClick={() => (onClick ? onClick(5) : () => "")}
      />
    </div>
  );
}
