import Link from "next/link";
import Image from "next/image";
import React from "react";
import { Product } from "@models/models";
import { useSelector } from "react-redux";
import {
  BRAND_THEME_SETTING_KEYS,
  selectBrandThemeSetting,
} from "@store/site-slice";

export default function FeaturedProducts({ product }: { product: Product }) {
  const product_detail_background_color = useSelector(
    selectBrandThemeSetting(
      BRAND_THEME_SETTING_KEYS.product_detail_background_color,
    ),
  );
  if (!product_detail_background_color) return <div>Loading...</div>;
  return (
    <div className={"flex flex-col gap-y-4"}>
      {product.featured_products.length !== 0 && (
        <div className={"font-bold"}>Featured Products</div>
      )}
      {product.featured_products.map((fp) => (
        <Link key={fp.id} href={`/e/${fp.featured_product.handle}`}>
          <div
            className="border-2 w-full"
            style={{ backgroundColor: product_detail_background_color }}
          >
            <div className={"flex"}>
              <Image
                alt={fp.featured_product.title}
                src={fp.featured_product.thumbnail as string}
                width={0}
                height={0}
                sizes="100vw"
                style={{
                  maxHeight: "100px",
                  width: "auto",
                  height: "auto",
                }}
              />
              <div className={"p-2 text-gray-900 flex items-center"}>
                {fp.featured_product.title}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}
